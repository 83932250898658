<script>
import issueData from '../data/bq_issues.json';

export default {
  name: 'ShowRegister',
  components: {},
  data() {
    return {
      issues: issueData,
      collapsedRows: [],
      statusMap: {
        'Resolution Found': 'B',
        Implemented: 'C',
        'No Resolution': 'A',
        'Deferral Proposed': 'D',
        Deferred: 'E',
        Resolved: 'F',
      },
      selectedIssueTypes: [],
      selectedStatuses: [],
      selectedPriorities: [],
      selectedActioners: [],
      selectedIssueCategories: [],
      filteredIssues: [],
      filters: {
        selectedStatuses: 'StatusLabel',
        selectedPriorities: 'Priority',
        selectedActioners: 'AllActioners',
        selectedIssueTypes: 'IssueType',
        selectedIssueCategories: 'IssueCategory',
      },
      unionActioners: 'any',
      filterShow: false,
      filterPauseHide: false,
      collapsedAllStatuses: false,
      issuesRefreshing: false,
      showProgress: false,
    };
  },
  computed: {
    isFiltered() {
      return this.issues.length !== this.filteredIssues.length;
    },
    availableStatuses() {
      return [
        ...new Set([
          ...this.filterIssuesExceptBy('selectedStatuses').map(
            issue => issue.StatusLabel
          ),
        ]).values(),
      ].sort();
    },
    intersectStatuses() {
      return this.availableStatuses.filter(status =>
        this.selectedStatuses.includes(status)
      );
    },
    availablePriorities() {
      return [
        ...new Set(
          this.filterIssuesExceptBy('selectedPriorities').map(
            issue => issue.Priority
          )
        ).values(),
      ].sort();
    },
    intersectPriorities() {
      return this.availablePriorities.filter(priority =>
        this.selectedPriorities.includes(priority)
      );
    },
    availableActioners() {
      return [
        ...new Set(
          this.filterIssuesExceptBy('selectedActioners').reduce(
            (actioners, issue) => {
              actioners.add(...issue.AllActioners.split(/,+\s?/));
              return actioners;
            },
            new Set()
          )
        ).values(),
      ].sort();
    },
    intersectActioners() {
      return this.availableActioners.filter(actioner =>
        this.selectedActioners.includes(actioner)
      );
    },
    availableIssueTypes() {
      return [
        ...new Set(
          this.filterIssuesExceptBy('selectedIssueTypes').map(
            issue => issue.IssueType
          )
        ).values(),
      ].sort();
    },
    intersectIssueTypes() {
      return this.availableIssueTypes.filter(issueType =>
        this.selectedIssueTypes.includes(issueType)
      );
    },
    availableIssueCategories() {
      return [
        ...new Set(
          this.filterIssuesExceptBy('selectedIssueCategories').map(
            issue => issue.IssueCategory
          )
        ).values(),
      ].sort();
    },
    intersectIssueCategories() {
      return this.availableIssueCategories.filter(issueCategory =>
        this.selectedIssueCategories.includes(issueCategory)
      );
    },
    viewer() {
      return this.$store.state.projectsViewer;
    },
    editor() {
      return this.$store.state.projectsEditorDb;
    },
    owner() {
      return this.$store.state.projectsOwner;
    },
    availableProjects() {
      return this.$store.getters.projectsViewable;
    },
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    projectId() {
      return this.$store.state.route.params.projectId;
    },
    projectName() {
      return this.$store.scopes[0].project.name;
    },
    scopeNumber() {
      return this.$store.state.route.params.scopeNumber;
    },
    userId() {
      return this.$store.state.userId;
    },
    projectNameDisplay() {
      const mode = this.projectNameDisplaySetting;
      const project = this.selectedProject[0];

      const code = mode.includes(0) ? project.code : '',
        key = mode.includes(1) ? project.projectId : '',
        name = mode.includes(2) ? project.name : '';

      let displayName = [code ? code : '', key ? key : '', name ? name : ''];

      if (mode.length === 0) {
        displayName = [project.name];
      }

      return displayName.filter(p => p).join(' ');
    },
    projectNameDisplaySetting() {
      return this.$store.state.projectNameDisplay;
    },
    sortedIssues() {
      const issues = [...this.filteredIssues];

      // If the collapsedRows set contains the parent collapse
      // then we need placeholder Rows only for that parent
      const statusOnlyRows = this.collapsedRows.reduce(
        (rows, row) => (row.type ? rows : rows.concat([row.status])),
        []
      );

      const collapsedRows = this.collapsedRows
        // Filter out rows that are not in the selected statuses
        .filter(row => this.selectedStatuses.includes(row.status))
        // Filter out rows that are not the parent collapse or are the parent collapse and are not a type
        .filter(
          row =>
            !statusOnlyRows.includes(row.status) ||
            (statusOnlyRows.includes(row.status) && !row.type)
        )
        // Filter out rows that are not in the selected issue types or have no type
        .filter(row => {
          return !row.type || this.selectedIssueTypes.includes(row.type);
        });

      const placeholderIssues = collapsedRows
        // Each collapsed row will have a placeholder issue row based on the filtered issues contained in the collapsed row
        .map(row => {
          const issueCount = issues.reduce((count, issue) => {
            if (row.status && row.type) {
              return issue.StatusLabel === row.status &&
                issue.IssueType === row.type
                ? count + 1
                : count;
            } else if (row.status) {
              return issue.StatusLabel === row.status ? count + 1 : count;
            }
            return count;
          }, 0);

          if (issueCount === 0) return { IssueKey: null };

          return {
            IssueKey: `(${issueCount} issue${issueCount > 1 ? 's' : ''})`,
            IssueType: row.type,
            IssueId: `${Math.abs(Math.random() * 100)}`,
            issueCount,
            StatusLabel: row.status,
            collapsedStatus: Boolean(row.status) && !row.type,
            collapsedType: Boolean(row.type),
          };
        })
        // Only placeholders with issues should be added to the issues table
        .filter(row => row.issueCount);

      const issueTable = issues
        .filter(issue => {
          return !collapsedRows.some(row =>
            row.status && row.type
              ? row.status === issue.StatusLabel && row.type === issue.IssueType
              : row.status
              ? row.status === issue.StatusLabel
              : false
          );
        })
        .concat(placeholderIssues)
        .sort((a, b) => {
          return (
            this.statusMap[a.StatusLabel].localeCompare(
              this.statusMap[b.StatusLabel]
            ) ||
            a.IssueType.localeCompare(b.IssueType) ||
            a.IssueId - b.IssueId
          );
        })
        .map((issue, i, issueArray) => {
          issue.statusRowSpan = false;
          issue.typeRowSpan = false;

          const priorIssueRow = issueArray[i - 1];
          const nextIssueRow = issueArray[i + 1];

          const priorStatus = priorIssueRow ? priorIssueRow.StatusLabel : null;
          const nextStatus = nextIssueRow ? nextIssueRow.StatusLabel : null;
          const priorType = priorIssueRow ? priorIssueRow.IssueType : null;
          const nextType = nextIssueRow ? nextIssueRow.IssueType : null;

          if (
            priorStatus !== issue.StatusLabel &&
            nextStatus === issue.StatusLabel
          ) {
            issue.statusRowSpan = true;
          }

          if (
            (priorStatus !== issue.StatusLabel ||
              priorType !== issue.IssueType) &&
            nextType === issue.IssueType &&
            nextStatus === issue.StatusLabel
          ) {
            issue.typeRowSpan = true;
          }

          return { ...issue };
        });

      return issueTable.concat([
        {
          StatusLabel: '',
          IssueType: '',
          IssueKey: `${issues.length} issue${issues.length === 1 ? '' : 's'}`,
          IssueId: 'total',
          issueCount: issues.length,
          collapsedStatus: true,
          collapsedType: true,
        },
      ]);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshIssues();
      this.collapseAll('type');
    });
  },
  created() {},
  methods: {
    initFilters() {
      Object.entries(this.filters).forEach(
        filter => {
          // if (this[filter[0]].length === 0) {
          if (filter[0] !== 'selectedActioners') {
            this[filter[0]] = [
              ...new Set(
                [...this.issues].map(issue => issue[filter[1]])
              ).values(),
            ];
          } else {
            this[filter[0]] = [
              ...new Set(
                [...this.issues].reduce((actioners, issue) => {
                  actioners.add(...issue[filter[1]].split(/,+\s?/));
                  return actioners;
                }, new Set())
              ).values(),
            ];
          }
        }
        // }
      );

      this.unionActioners = 'any';
      this.filteredIssues = this.filterIssuesExceptBy();
    },
    filterIssuesExceptBy(filterName) {
      const remainingFilters = Object.entries(this.filters).filter(
        filter => filter[0] !== filterName
      );
      const partiallyFilteredIssues = [...this.issues].filter(issue => {
        return remainingFilters.every(f => {
          const filter = f[0];
          const filterKey = f[1];

          if (filter !== 'selectedActioners') {
            return this[filter].includes(issue[filterKey]);
          } else {
            const actioners = issue[filterKey].split(/,+\s?/);
            const selectedActioners = Array.isArray(this[filter])
              ? this[filter]
              : [this[filter]];
            switch (this.unionActioners) {
              case 'any':
                return actioners.some(actioner =>
                  selectedActioners.includes(actioner)
                );
              case 'only':
                return (
                  actioners.length === 1 &&
                  selectedActioners.every(actioner =>
                    actioners.includes(actioner)
                  )
                );
              case 'all':
                return selectedActioners.every(actioner =>
                  actioners.includes(actioner)
                );
              case 'exclusive':
                return selectedActioners.every(
                  actioner => !actioners.includes(actioner)
                );
            }
          }
        });
      });
      return partiallyFilteredIssues;
    },
    selectProject(value) {
      this.$store.dispatch('clearScopeId').then(() => {
        this.$router.push({
          name: 'register',
          params: {
            ...value,
          },
        });
      });
    },
    collapseRow(status, type) {
      const rows = [...this.collapsedRows];
      const comparisonIssues = [...this.issues];

      // if all statuses have been collapse set the collapsed status to true

      const allStatuses = [...this.issues].reduce((statuses, issue) => {
        return statuses.add(`${issue.StatusLabel}`);
      }, new Set());

      // Collapse the status only
      if (!type) {
        const typeRowsWithStatus = rows.filter(
          row => row.status === status && row.type
        );

        this.collapsedRows = rows
          .filter(row => row.status !== status)
          .concat([{ status: status }])
          .concat(typeRowsWithStatus);

        const statusRows = this.collapsedRows.filter(row => !row.type);

        if (
          JSON.parse(JSON.stringify(statusRows)).length ===
          [...allStatuses].length
        ) {
          this.collapsedAllStatuses = true;
        }

        return;
      }

      const issuesWithStatus = comparisonIssues.filter(
        issue => issue.StatusLabel === status
      );

      const issueTypes = issuesWithStatus.reduce((types, issue) => {
        return types.add(issue.IssueType);
      }, new Set());

      const rowTypes = rows.reduce((types, row) => {
        return types.add(row.type);
      }, new Set());

      rowTypes.add(type);
      const intersection = new Set(
        [...issueTypes].filter(x => !rowTypes.has(x))
      );

      // This OptionFlag relates to the option to reset collapse all types in a
      // status if all types are collapsed
      // TODO: Decide if this is a good option to have, if not the intersection check can be removed
      const OptionFlag = false;

      if (intersection.size === 0 && OptionFlag) {
        this.collapsedRows = rows
          .filter(row => row.status !== status)
          .concat({ status: status });
      } else {
        this.collapsedRows = rows.concat([{ status: status, type: type }]);
      }
    },
    expandRow(status, type) {
      const rows = [...this.collapsedRows];
      if (type) {
        this.collapsedRows = rows.filter(row => {
          return (
            row.status !== status ||
            (row.status === status && row.type !== type)
          );
        });
      } else {
        this.collapsedRows = rows.filter(
          row => row.status !== status || row.type
        );
      }
      this.collapsedAllStatuses = false;
    },
    selectIssueType(value) {
      // TODO: decide if the collapsed rows should be cleared by a filter change
      // this.collapsedRows = [
      //   ...this.collapsedRows.filter(row => value.includes(row.type)),
      // ];

      const intersect = this.selectedIssueTypes.filter(
        x => this.availableIssueTypes.includes(x) && value.includes(x)
      );

      const availableSelectIntersect = value.filter(x =>
        this.availableIssueTypes.includes(x)
      );
      //TODO: Match the select actioners logic for none selected
      if (intersect.length === 0) {
        this.selectedIssueTypes = [
          ...new Set(
            this.filterIssuesExceptBy('selectedIssueTypes').map(
              issue => issue.IssueType
            )
          ),
        ];
      } else {
        this.selectedIssueTypes = availableSelectIntersect;
      }

      this.filteredIssues = this.filterIssuesExceptBy();
    },
    selectIssueCategory(value) {
      const intersect = this.selectedIssueCategories.filter(
        x => this.availableIssueCategories.includes(x) && value.includes(x)
      );

      const availableSelectIntersect = value.filter(x =>
        this.availableIssueCategories.includes(x)
      );
      //TODO: Match the select actioners logic for none selected
      if (intersect.length === 0) {
        this.selectedIssueCategories = [
          ...new Set(
            this.filterIssuesExceptBy('selectedIssueCategories').map(
              issue => issue.IssueCategory
            )
          ),
        ];
      } else {
        this.selectedIssueCategories = availableSelectIntersect;
      }

      this.filteredIssues = this.filterIssuesExceptBy();
    },
    selectStatus(value) {
      // TODO: Decide if the collapsed rows should be cleared by a filter change
      // this.collapsedRows = [
      //   ...this.collapsedRows.filter(row => value.includes(row.status)),
      // ];

      const intersect = this.selectedStatuses.filter(
        x => this.availableStatuses.includes(x) && value.includes(x)
      );

      const availableSelectIntersect = value.filter(x =>
        this.availableStatuses.includes(x)
      );
      //TODO: Match the select actioners logic for none selected
      if (intersect.length === 0) {
        this.selectedStatuses = [
          ...new Set(
            this.filterIssuesExceptBy('selectedStatuses').map(
              issue => issue.StatusLabel
            )
          ),
        ];
      } else {
        this.selectedStatuses = availableSelectIntersect;
      }
      this.filteredIssues = this.filterIssuesExceptBy();
    },
    selectPriority(value) {
      const intersect = this.selectedPriorities.filter(
        x => this.availablePriorities.includes(x) && value.includes(x)
      );

      const availableSelectIntersect = value.filter(x =>
        this.availablePriorities.includes(x)
      );
      //TODO: Match the select actioners logic for none selected
      if (intersect.length === 0) {
        this.selectedPriorities = [
          ...new Set(
            this.filterIssuesExceptBy('selectedPriorities').map(
              issue => issue.Priority
            )
          ),
        ];
      } else {
        this.selectedPriorities = availableSelectIntersect;
      }

      this.filteredIssues = this.filterIssuesExceptBy();
    },
    selectActioner(value) {
      if (!Array.isArray(value)) value = [value];

      const currentlySelected = [...this.selectedActioners];

      const intersect = currentlySelected.filter(
        x => this.availableActioners.includes(x) && value.includes(x)
      );
      console.log(value);
      const availableSelectIntersect = value.filter(x =>
        this.availableActioners.includes(x)
      );

      const deselectedOption = this.availableActioners.filter(
        x => !value.includes(x)
      );

      if (this.unionActioners === 'only') {
        this.selectedActioners = value[0];
      } else if (
        this.availableActioners.length <= 1 &&
        intersect.length === 0
      ) {
        this.selectedActioners = currentlySelected;
      } else if (this.availableActioners.length > 1 && intersect.length > 0) {
        this.selectedActioners = [
          ...new Set(
            currentlySelected
              .filter(x => !deselectedOption.includes(x))
              .concat(availableSelectIntersect)
          ).values(),
        ];
      } else if (intersect.length === 0) {
        this.selectedActioners = [
          ...new Set(
            currentlySelected.concat(this.availableActioners)
          ).values(),
        ].sort();
      } else {
        this.selectedActioners = availableSelectIntersect;
      }
      console.log({ selected: this.selectedActioners });
      this.filteredIssues = this.filterIssuesExceptBy();
    },
    setUnionActioners(value) {
      const availableActioners = this.availableActioners;

      if (value === 'only') {
        this.selectedActioners = Array.isArray(this.selectedActioners)
          ? availableActioners.includes(this.selectedActioners[0])
            ? this.selectedActioners[0]
            : availableActioners[0]
          : this.selectedActioners;
      } else if (!Array.isArray(this.selectedActioners)) {
        if (availableActioners.includes(this.selectedActioners)) {
          this.selectedActioners = [this.selectedActioners];
        } else {
          this.selectedActioners = availableActioners;
        }
      }

      this.filteredIssues = this.filterIssuesExceptBy();
    },
    expandAll(field) {
      this.collapsedRows =
        field === 'status'
          ? JSON.parse(JSON.stringify(this.collapsedRows)).filter(x => x.type)
          : JSON.parse(JSON.stringify(this.collapsedRows)).filter(x => !x.type);
      if (field === 'status') {
        this.collapsedAllStatuses = false;
      }
    },
    collapseAll(field) {
      const collapsedRows = [...this.issues].reduce(
        (acc, issue) => {
          if (field === 'status') {
            const x = acc.find(x => x.status === issue.StatusLabel && !x.type);
            if (!x) {
              return [{ status: `${issue.StatusLabel}` }].concat(acc);
            }
          }
          if (field === 'type') {
            const x = acc.find(
              x => x.type === issue.IssueType && x.status === issue.StatusLabel
            );
            if (!x) {
              return acc.concat([
                { status: `${issue.StatusLabel}`, type: `${issue.IssueType}` },
              ]);
            }
          }
          return acc;
        },
        field === 'status'
          ? JSON.parse(JSON.stringify(this.collapsedRows)).filter(x => x.type)
          : JSON.parse(JSON.stringify(this.collapsedRows)).filter(x => !x.type)
      );

      this.collapsedRows = collapsedRows;
      if (field === 'status') {
        this.collapsedAllStatuses = true;
      }
    },
    hideFilters() {
      // after a delay set filterShow to false
      if (!this.filterPauseHide) {
        setTimeout(() => {
          this.filterShow = false;
        }, 1000);
      }
    },
    refreshIssues() {
      this.issuesRefreshing = true;
      this.showProgress = true;

      setTimeout(() => {
        this.issuesRefreshing = false;
        this.showProgress = false;

        this.initFilters();
      }, Math.random() * 4000 + 1000);
    },
    initialise(allActioners) {
      const actioners = allActioners.split(/,+\s?/);

      return actioners
        .map(a => a.match(/^([A-Z]{3})/i)[1])
        .map(a => a.toUpperCase())
        .sort();
    },
    projectKey(issueKey) {
      const projectKey = issueKey && issueKey.match(/([A-Z]+)-\d+/);
      console.log({ issueKey, projectKey });
      return projectKey && projectKey[1];
    },
    issueIndex(issueKey) {
      const issueIndex = issueKey && issueKey.match(/([A-Z]+)-(\d+)/);
      console.log({ issueKey, issueIndex });
      return issueIndex && issueIndex[2];
    },
  },
};
</script>

<template>
  <div style="position: relative">
    <span
      v-if="selectedProject[0]"
      class="text-h6 px-3 pt-3 d-flex justify-space-between table-title"
      style="margin-right: 15px"
    >
      {{ selectedProject[0] && projectNameDisplay }}
      <div>
        <div text class="refresh-button" @click="refreshIssues">Refresh</div>
        <div text class="filter-button" @click="filterShow = !filterShow">
          Filter
          <v-icon>{{
            filterShow ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </div>
      </div>
    </span>

    <div class="filters" @mouseleave="hideFilters">
      <v-expand-transition>
        <div v-show="filterShow">
          <v-row class="px-2">
            <v-col cols="2" class="statuses selector">
              <v-select
                :value="selectedStatuses"
                dense
                multiple
                :items="availableStatuses"
                label="Status"
                @change="selectStatus"
                @mousedown="filterPauseHide = true"
                @blur="filterPauseHide = false"
                ><template #selection="{ item, index }"
                  ><span v-if="index === 0">{{ item }}</span
                  ><span v-else-if="index === 1">
                    &nbsp;+ {{ intersectStatuses.length - 1 }} other{{
                      intersectStatuses.length > 2 ? 's' : ''
                    }}</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col class="priorities selector" cols="2">
              <v-select
                :value="selectedPriorities"
                dense
                multiple
                :items="availablePriorities"
                label="Priority"
                @change="selectPriority"
                @mousedown="filterPauseHide = true"
                @blur="filterPauseHide = false"
              >
                <template #selection="{ item, index }"
                  ><span v-if="index === 0">{{ item }}</span
                  ><span v-else-if="index === 1">
                    &nbsp;+ {{ intersectPriorities.length - 1 }} other{{
                      intersectPriorities.length > 2 ? 's' : ''
                    }}</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col class="selector types" cols="2">
              <v-select
                :value="selectedIssueCategories"
                dense
                multiple
                :items="availableIssueCategories"
                label="Issue Category"
                @change="selectIssueCategory"
                @mousedown="filterPauseHide = true"
                @blur="filterPauseHide = false"
              >
                <template #selection="{ item, index }"
                  ><span v-if="index === 0">{{ item }}</span
                  ><span v-else-if="index === 1">
                    &nbsp;+ {{ intersectIssueCategories.length - 1 }} other{{
                      intersectIssueCategories.length > 2 ? 's' : ''
                    }}</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col class="selector types" cols="2">
              <v-select
                :value="selectedIssueTypes"
                dense
                multiple
                :items="availableIssueTypes"
                label="Issue Type"
                @change="selectIssueType"
                @mousedown="filterPauseHide = true"
                @blur="filterPauseHide = false"
              >
                <template #selection="{ item, index }"
                  ><span v-if="index === 0">{{ item }}</span
                  ><span v-else-if="index === 1">
                    &nbsp;+ {{ intersectIssueTypes.length - 1 }} other{{
                      intersectIssueTypes.length > 2 ? 's' : ''
                    }}</span
                  >
                </template>
              </v-select></v-col
            >
            <v-col class="selector actors" cols="2">
              <v-select
                :value="selectedActioners"
                dense
                :multiple="unionActioners !== 'only'"
                :items="availableActioners"
                label="Action Required From"
                @change="selectActioner"
                @mousedown="filterPauseHide = true"
                @blur="filterPauseHide = false"
              >
                <template #selection="{ item, index }"
                  ><span v-if="index === 0">{{ item }}</span
                  ><span v-else-if="index === 1">
                    &nbsp;+ {{ intersectActioners.length - 1 }} other{{
                      intersectActioners.length > 2 ? 's' : ''
                    }}</span
                  >
                </template>
              </v-select>
              <v-radio-group
                v-model="unionActioners"
                mandatory
                dense
                @change="setUnionActioners"
              >
                <v-radio dense label="Any" value="any" />
                <v-radio dense label="All" value="all" />
                <v-radio dense label="Only" value="only" />
                <v-radio dense label="Exclusive" value="exclusive" />
              </v-radio-group>
            </v-col>
            <v-col v-if="isFiltered"
              ><v-btn text @click="initFilters">Clear Filters</v-btn></v-col
            >
          </v-row>
        </div></v-expand-transition
      >
    </div>
    <table
      class="issue-table"
      :class="{
        s600: $vuetify.breakpoint.xs,
        's600-960': $vuetify.breakpoint.sm,
        's960-1264': $vuetify.breakpoint.md,
        's1264-1904': $vuetify.breakpoint.lg,
        s1904: $vuetify.breakpoint.xl,
      }"
    >
      <thead :class="{ refreshing: issuesRefreshing }">
        <tr class="collapsedType collapsedStatus">
          <th class="status" :class="{ 'col-span': collapsedAllStatuses }">
            Status
            <span
              title="Collapse All Statuses"
              text
              class="collapser"
              @click="collapseAll('status')"
              >◀︎</span
            ><span
              title="Expand All Statuses"
              text
              class="collapser"
              @click="expandAll('status')"
              >▶︎</span
            >
          </th>
          <th
            class="type"
            :class="{ 'all-statuses-collapsed': collapsedAllStatuses }"
          >
            Type
            <span
              title="Collapse All Types"
              text
              class="collapser"
              @click="collapseAll('type')"
              >◀︎</span
            ><span
              title="Expand All Types"
              text
              class="collapser"
              @click="expandAll('type')"
              >▶︎</span
            >
          </th>
          <th class="issue">Key</th>
          <th class="priority">Priority</th>
          <th class="dbl issue-title">Title</th>
          <th class="dbl issue-action">Action Required</th>
          <th class="actioners">
            From
            <!-- <span v-if="sortedIssues.slice(-1)[0].issueCount" class="issue-key">
              {{ sortedIssues.slice(-1)[0].IssueKey }}
            </span> -->
          </th>
        </tr>
      </thead>
      <tbody class="progress">
        <tr>
          <td colspan="7" style="padding: 0">
            <v-progress-linear
              :indeterminate="issuesRefreshing"
              :active="showProgress"
              color="secondary"
              height="2"
              style="z-index: 10"
            />
          </td>
        </tr>
      </tbody>
      <tbody :class="{ refreshing: issuesRefreshing }">
        <tr
          v-for="(issue, i) in sortedIssues"
          :key="issue.IssueId"
          class="px-5 issue-row mr-5 flex-nowrap"
          :class="{
            [issue.Priority]: issue.Priority,
            collapsedType: issue.collapsedType,
            collapsedStatus: issue.collapsedStatus,
            top: i === 0,
          }"
        >
          <td
            v-if="!(sortedIssues[i - 1] && sortedIssues[i - 1].statusRowSpan)"
            class="status"
            :class="{
              top: i === 0,
              lined:
                !sortedIssues[i - 1] ||
                (sortedIssues[i - 1] &&
                  issue.StatusLabel !== sortedIssues[i - 1].StatusLabel),
              unlined:
                sortedIssues[i - 1] &&
                issue.StatusLabel === sortedIssues[i - 1].StatusLabel,
              'row-span': issue.statusRowSpan,
              'col-span': collapsedAllStatuses,
            }"
            :rowspan="issue.statusRowSpan ? 2 : 1"
            :colspan="collapsedAllStatuses ? 2 : 1"
          >
            <div class="d-flex">
              <span class="type-label">{{ issue.StatusLabel }}</span
              ><span
                v-if="issue.collapsedStatus"
                text
                class="collapser"
                :class="{
                  empty: !issue.StatusLabel || issue.StatusLabel === '',
                }"
                @click="expandRow(issue.StatusLabel)"
                >▶︎</span
              ><span
                v-else
                text
                class="collapser"
                :class="{
                  empty: !issue.StatusLabel || issue.StatusLabel === '',
                }"
                @click="collapseRow(issue.StatusLabel)"
                >◀︎</span
              >
            </div>
          </td>
          <td
            v-if="
              !(sortedIssues[i - 1] && sortedIssues[i - 1].typeRowSpan) &&
              !collapsedAllStatuses
            "
            class="type"
            :class="{
              top: i === 0,
              lined:
                !sortedIssues[i - 1] ||
                (sortedIssues[i - 1] &&
                  issue.StatusLabel !== sortedIssues[i - 1].StatusLabel) ||
                (sortedIssues[i - 1] &&
                  issue.IssueType !== sortedIssues[i - 1].IssueType),
              unlined:
                sortedIssues[i - 1] &&
                issue.StatusLabel === sortedIssues[i - 1].StatusLabel &&
                issue.IssueType === sortedIssues[i - 1].IssueType,
              'row-span': issue.typeRowSpan,
            }"
            :rowspan="issue.typeRowSpan ? 2 : 1"
          >
            <div
              v-if="issue.collapsedType"
              justify-space-between
              class="d-flex"
              @click="expandRow(issue.StatusLabel, issue.IssueType)"
            >
              <span class="type-label">{{ issue.IssueType }}</span>
              <span
                class="collapser"
                :class="{ empty: !issue.IssueType || issue.IssueType === '' }"
                >▶︎</span
              >
            </div>
            <div
              v-else
              justify-space-between
              class="d-flex"
              @click="collapseRow(issue.StatusLabel, issue.IssueType)"
            >
              <span class="type-label">{{ issue.IssueType }}</span>
              <span
                :class="{ empty: !issue.IssueType || issue.IssueType === '' }"
                text
                class="collapser"
                >◀︎</span
              >
            </div>
          </td>
          <td
            class="lined issue"
            :colspan="issue.collapsedType || issue.collapsedStatus ? 5 : 1"
            :class="{
              hide: issue.issueCount === 0,
              count: issue.issueCount > 0,
            }"
          >
            <span class="issue-key"
              ><span class="project-key">{{ projectKey(issue.IssueKey) }}</span
              ><span class="issue-index">{{ issueIndex(issue.IssueKey) }}</span
              ><span class="issue-key-source">{{ issue.IssueKey }}</span></span
            >
          </td>

          <td
            v-if="!issue.collapsedType && !issue.collapsedStatus"
            class="lined priority"
          >
            {{ issue.Priority }}
          </td>
          <td
            v-if="!issue.collapsedType && !issue.collapsedStatus"
            cols="3"
            class="lined dbl"
            :title="issue.Title"
          >
            {{ issue.Title }}
          </td>
          <td
            v-if="!issue.collapsedType && !issue.collapsedStatus"
            class="lined dbl"
            :title="issue.ActionRequired"
          >
            {{ issue.ActionRequired }}
          </td>
          <td
            v-if="!issue.collapsedType && !issue.collapsedStatus"
            class="lined actioners"
          >
            <span class="full">
              {{ issue.AllActioners }}{{ issue.AssignedOrg }}
            </span>
            <span class="initials">
              <span
                v-for="(init, i) in initialise(issue.AllActioners)"
                :key="i"
              >
                {{ init }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <pre>
    <!-- {{ JSON.stringify(sortedIssues, null, 2) }} -->
    </pre>
  </div>
</template>

<style scoped lang="scss">
.table-title {
  padding-left: 18px !important;
}
.filter-button,
.refresh-button {
  font-size: 0.875rem;
  text-transform: uppercase;
  opacity: 0.6;
  position: relative;
  margin-right: -5px;
  display: inline-block;
  margin-left: 15px;
}

.filter-button:hover,
.refresh-button:hover {
  opacity: 1;
  cursor: pointer;
}

.issue-table {
  position: relative;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  padding: 0 0 100px;
  padding-right: 25px;
  border-spacing: 0;
  table-layout: fixed;
  padding-left: 15px;
}
.filters {
  background-color: #eee;
  margin-right: 25px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-top: 10px;
}
.filters .row {
  padding: 5px;
  margin: 0;
}

.issue-table .issue-row,
.issue-table th {
  font-size: 0.7rem;
}

.issue-table td {
  padding: 0.25rem;
  margin: 0;
  vertical-align: top;
}

.issue-table th {
  text-align: left;
  padding-right: 0.5rem;
}

.dbl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.issue-table .issue {
  white-space: nowrap;
}

.issue-key,
.priority {
  min-width: 46px;
}
.issue-key {
  font-weight: 900;
}

.collapsedStatus .issue-key,
.collapsedType .issue-key {
  font-weight: 400;
}

.priority {
  color: val(--v-primary-base);
}

.lined {
  border: 0;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.unlined {
  border-top: thin solid transparent;
  color: transparent;
}

td.unlined .collapser,
.collapser.empty {
  visibility: hidden;
}

th.status span,
th.type span {
  opacity: 0.15;
}

thead:hover .status span,
thead:hover .type span {
  opacity: 0.5;
}

.collapser {
  padding-left: 1ex;
  cursor: pointer;
  display: inline-block;
  min-width: 20px;
  color: var(--v-primary-base);
  font-size: 0.7rem;
}

.issue-row:hover .col {
  background-color: #e0e0e0;
}

.lined:not(.dbl),
.unlined:not(.dbl) {
  width: 10%;
}

.dbl {
  width: 25%;
}

.priority {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.issue:not(.dbl),
.priority:not(.dbl) {
  width: 6.25%;
}

.col.flex-nowrap {
  white-space: nowrap;
}
.status,
.type {
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.status div,
.type div {
  display: flex;
  justify-content: space-between;
}

.type .type-label,
.status .type-label {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.col-span.status .type-label {
  overflow-x: visible;
  text-overflow: initial;
  display: inline-block;
}

.col-span.status {
  display: table-cell;
  /* width: 15%; */
}

.row-span.status .type-label,
.row-span.type .type-label {
  overflow-x: visible;
  white-space: normal;
}

.issue-row:last-of-type .col {
  margin-bottom: 1rem;
}

.issue span {
  display: inline-block;
  width: 50%;
  margin: 0;
}

.issue .priority {
  padding: 0 1em;
}
.all-statuses-collapsed {
  visibility: hidden;
}

.issue-table td,
.issue-table td {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.refreshing td,
.refreshing th {
  opacity: 0.25;
  transition: opacity 0.5s ease-in;
  cursor: wait;
}

.progress {
  position: relative;
}
.progress td {
  position: absolute;
  bottom: -1px;
  height: 2px;
  width: 100%;
  z-index: 10;
  visibility: hidden;
}
.refreshing + .progress td {
  visibility: visible;
}

.hide {
  display: none;
}

.issue-table th {
  /* text-transform: uppercase; */
  font-size: 0.6rem;
  padding-left: 1ex;
  color: var(--v-secondary-base);
}

.issue.count {
  color: var(--v-primary-darken2);
}

.actioners .initials {
  display: none;
}

.issue {
  .project-key,
  .issue-index {
    display: none;
    &:empty {
      display: none;
    }
  }
}

.s600,
.s600-960 {
  &.issue-table .issue-row {
    font-size: 0.6rem;
  }
  .actioners {
    width: 7.5% !important;
    .initials {
      display: inline;
    }
    .full {
      display: none;
    }
  }
  .priority {
    width: 5% !important;
    font-size: 0;
    font-weight: 500;
    &::first-letter {
      font-size: 0.6rem;
    }
  }
  th {
    font-size: 0;
    &::first-letter {
      font-size: 0.6rem;
    }
  }

  .issue {
    width: 7.5%;
    padding-right: 0;
    margin-right: 0;
    .project-key {
      font-size: 0;
      width: auto;
      display: inline;

      &::first-letter {
        font-size: 0.6rem;
      }

      &:not(:empty) + .issue-index::before {
        content: '-';
      }
    }
    .issue-index,
    .issue-key {
      display: inline;
      width: auto;
    }
  }
  tr:not(.collapsedType) {
    .issue .issue-key-source {
      display: none;
    }
  }
  .dbl {
    width: 20%;
  }
}
</style>
